<script setup lang="ts">
import { onMounted } from 'vue'

const groupTypes = [
    {
        label: 'Friends',
        value: 'friends'
    },
    {
        label: 'Junior/Primary School',
        value: 'juniorSchool'
    },
    {
        label: 'High/Secondary School',
        value: 'highSchool'
    },
    {
        label: 'College/University',
        value: 'college'
    },
    {
        label: 'Business Trip',
        value: 'business'
    },
    {
        label: 'Stag/Hen/Bachelor Party',
        value: 'party'
    },
    {
        label: 'Sports Group',
        value: 'sports'
    },
    {
        label: 'Cultural Group',
        value: 'cultural'
    }
]

const groupType = defineModel({
    type: String,
    required: true
})

onMounted(() => {
    if (!groupType.value) {
        groupType.value = groupTypes[0].value
    }
})
</script>

<template>
    <div
        class="hz-group-types-select d-flex flex-column justify-content-center align-items-start mb-3"
    >
        <label class="pre-title mb-2">Group Type <span class="text-danger">*</span></label>

        <select v-model="groupType" class="form-control">
            <option
                v-for="(item, index) in groupTypes"
                :key="`groupType-${ index }`"
                :value="item.value"
                :selected="index === 0"
            >
                {{ item.label }}
            </option>
        </select>
    </div>
</template>